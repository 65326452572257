import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import './Carousel.css';
import AdminPanel from './adminpanel';

function AboutUsTeamMember() {
  const [name, setName] = useState('');
  const [initialData, setInitialData] = useState(null);
  const [position, setPosition] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [sociallist1, setSocialList1] = useState('');
  const navigate = useNavigate();
  const [teamMembers, setTeamMembers] = useState([]);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [noChangesModal, setNoChangesModal] = useState(false);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setImagePreviewUrl(URL.createObjectURL(selectedFile));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setImagePreviewUrl(URL.createObjectURL(droppedFile));
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = () => {
    axios.get('https://pineappleai-ai.com/api/aboutus_teammember/view')
      .then(result => setTeamMembers(result.data))
      .catch(err => console.log(err));
  };

  const validateForm = () => {
    if (name.trim() === '' || position.trim() === '') {
      setError('Name and Position cannot be empty.');
      return false;
    }
    if (!/[^0-9]/.test(name)) {
      setError('Name can only contain letters and spaces.');
      return false;
    }
    return true;
  };

  const hasChanges = () => {
    return (
      name !== initialData.name ||
      position !== initialData.position ||
      file !== initialData.imageUrl ||
      sociallist1 !== initialData.socialMedia.sociallist1
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    if (currentItemId) {
      if (!hasChanges()) {
        setNoChangesModal(true);
        return;
      }
    }

    if (!name || !position || !file || !sociallist1) {
      setErrorModal(true)
      return;
    }

    if (validateForm()) {
      currentItemId ? setShowUpdateModal(true) : setShowAddModal(true);
    }
  };

  const confirmUpdate = () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('position', position);
    formData.append('imageUrl', file);
    formData.append('sociallist1', sociallist1);



    if (currentItemId) {
      axios.put(`https://pineappleai-ai.com/api/aboutus_teammember/updateUser/${currentItemId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(result => {
          console.log(result);
          setName('');
          setPosition('');
          setFile(null);
          setImagePreviewUrl(null);
          setSocialList1('');
          setCurrentItemId(null);
          fetchTeamMembers();
          navigate('/AboutUsTeamMember');
          setShowUpdateModal(false); // Close the modal
        })
        .catch(err => {
          console.error('Error updating form:', err);
          alert('There was an error updating the form!');
          setShowUpdateModal(false); // Close the modal
        });
    } else {
      axios.post("https://pineappleai-ai.com/api/aboutus_teammember/create", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(result => {
          console.log(result);

          setName('');
          setPosition('');
          setFile(null);
          setImagePreviewUrl(null);
          setSocialList1('');
          fetchTeamMembers();
          navigate('/AboutUsTeamMember');
          setShowAddModal(false)// Close the modal
        })
        .catch(err => {
          console.error('Error submitting form:', err);
          alert('There was an error submitting the form!');
          setShowAddModal(false); // Close the modal
        });
    }
  };

  const handleDelete = (id) => {
    setShowModal(true);
    setDeleteItemId(id);
  };

  const confirmDelete = () => {
    if (deleteItemId) {
      axios.delete(`https://pineappleai-ai.com/api/aboutus_teammember/deleteUser/${deleteItemId}`)
        .then(response => {
          console.log(response.data);
          setShowModal(false);
          setDeleteItemId(null);
          fetchTeamMembers();
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleUpdate = (item) => {
    setInitialData(item);
    setName(item.name);
    setPosition(item.position);
    setFile(item.imageUrl);
    setImagePreviewUrl(`https://pineappleai-ai.com/api/images/${item.imageUrl}`);
    setSocialList1(item.socialMedia.sociallist1);
    setCurrentItemId(item._id);
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode >= 48 && charCode <= 57) { // Check if the character is a number (0-9)
      e.preventDefault();
    }
  };

  return (
    <AdminPanel>
      <div className='container mt-5'>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='p-4 border rounded' style={{ width: '100%', maxWidth: '600px' }}>
            <h2 className='text-center mb-4'>About Us Team Member</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label className='form-label'>Name</label>
                <input
                  type="text"
                  placeholder='Enter name'
                  className='form-control'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyPress={handleKeyPress} // Restrict numbers
                  required
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Position</label>
                <input
                  type="text"
                  placeholder='Enter position'
                  className='form-control'
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  onKeyPress={handleKeyPress} // Restrict numbers
                  required
                />
              </div>
              <div className="mb-3">
              <label className="form-label">Image 1</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleImageChange}
              />
              {imagePreviewUrl && <img src={imagePreviewUrl} alt="Image Preview" width="200" />}
            </div>
            <div
              className="mb-3"
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              style={{ border: "2px dashed #ccc", padding: "10px", borderRadius: "10px" }}
            >
              <p>Drag and drop Image 1 here</p>
              
            </div>
              <div className='mb-3'>
                <label className='form-label'>Social Media 1</label>
                <input
                  type="text"
                  placeholder='Enter social media link 1'
                  className='form-control'
                  value={sociallist1}
                  onChange={(e) => setSocialList1(e.target.value)}
                />
              </div>
              <div className='d-flex justify-content-center'>
                <button type='submit' className='btn btn-primary'>{currentItemId ? 'Update' : 'Add'}</button>
              </div>
            </form>
          </div>
        </div>

        <div className='mt-5 d-flex justify-content-center'>
          <div className="table-responsive" style={{ maxWidth: '1000px' }}>
            <h3 className='text-center'>Team Members</h3>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Social Media</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {teamMembers && (teamMembers.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <img src={`https://pineappleai-ai.com/api/images/${item.imageUrl}`} alt={item.name} width="50" />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.position}</td>
                    <td>{item.socialMedia.sociallist1}</td>
                    <td>
                      <button className='btn btn-primary me-2' onClick={() => handleUpdate(item)}>Edit</button>
                      <button className='btn btn-danger' onClick={() => handleDelete(item._id)}>Delete</button>
                    </td>
                  </tr>
                )))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update this team member's details?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={confirmUpdate}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this team member's details?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={confirmUpdate}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={errorModal} onHide={() => setErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please fill in all required fields.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* No Changes Modal */}
      <Modal show={noChangesModal} onHide={() => setNoChangesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>No Changes Detected</Modal.Title>
        </Modal.Header>
        <Modal.Body>No changes were made. Please update the form before saving.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setNoChangesModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </AdminPanel>
  );
}

export default AboutUsTeamMember;
