import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'animate.css';
import axios from 'axios';

import { NavLink } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "./Footer";

function Home() {
  const [loading, setLoading] = useState(true);
  const [carouselData, setCarouselData] = useState(null);
  const [projects, setProjects] = useState(null);
  const [data_teams_header, setTeamsHeader] = useState(null);
  const [data_teams_member, setTeamsMember] = useState(null);
  const [data, setData] = useState(null);

  const [carouselLoading, setCarouselLoading] = useState(true);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [aboutUsLoading, setAboutUsLoading] = useState(true);
  const [teamLoading, setTeamLoading] = useState(true);

  const headerOptions = {
    items: 1,
    loop: true,
    autoplay: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    dots: false,
    nav: true,
    navText: ["<i class='bi bi-chevron-left'></i>", "<i class='bi bi-chevron-right'></i>"],
  };

  const projectOptions = {
    items: 3,
    loop: true,
    autoplay: true,
    margin: 30,
    dots: true,
    nav: true,
    navText: ["<i class='bi bi-chevron-left'></i>", "<i class='bi bi-chevron-right'></i>"],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      992: { items: 3 }
    }
  };

  const teamOptions = {
    items: 4,
    loop: true,
    autoplay: true,
    margin: 30,
    dots: false,
    nav: true,
    navText: ["<i class='bi bi-chevron-left'></i>", "<i class='bi bi-chevron-right'></i>"],
    responsive: {
      0: { items: 1 },
      576: { items: 2 },
      768: { items: 3 },
      992: { items: 4 }
    }
  };

  // Fetch Carousel Data
  useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const response = await axios.get('https://pineappleai-ai.com/api/carousel');
        setCarouselData(response.data);
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      } finally {
        setCarouselLoading(false);
      }
    };
    fetchCarouselData();
  }, []);

  // Fetch Projects Data
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://pineappleai-ai.com/api/project/view');
        setProjects(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setProjectsLoading(false);
      }
    };
    fetchProjects();
  }, []);

  // Fetch About Us and Team Data
  useEffect(() => {
    const fetchAboutUsData = async () => {
      try {
        const aboutUsResponse = await axios.get('https://pineappleai-ai.com/api/aboutus/view1');
        setData(aboutUsResponse.data);
      } catch (err) {
        console.error(err);
      } finally {
        setAboutUsLoading(false);
      }
    };

    const fetchTeamsData = async () => {
      try {
        const teamMembersResponse = await axios.get('https://pineappleai-ai.com/api/aboutus_teammember/view');
        setTeamsMember(teamMembersResponse.data);
      } catch (err) {
        console.error(err);
      } finally {
        setTeamLoading(false);
      }
    };

    fetchAboutUsData();
    fetchTeamsData();
  }, []);

  // Control global loading state based on all individual loading states
  useEffect(() => {
    if (!carouselLoading && !projectsLoading && !aboutUsLoading && !teamLoading) {
      setLoading(false);
    }
  }, [carouselLoading, projectsLoading, aboutUsLoading, teamLoading]);

  if (loading) {
    return (
      <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />

      {/* Header Carousel Start */}
      <OwlCarousel className="header-carousel owl-carousel" {...headerOptions}>
        {carouselData.map((item, index) => (
          <div key={index} className="header-carousel-item">
            <div className="header-carousel-item-img">
              <img src={`https://pineappleai-ai.com/api/images/${item.carousel_img_1}`} className="img-fluid w-60" alt={`Carousel Image ${index + 1}`} />
            </div>
            <div className="carousel-caption">
              <div className="carousel-caption-inner text-start p-3">
                <p className="display-4 text-center gradient-text mb-1 fadeInUp animate__animated"
                  style={{ animationDelay: '1.3s', fontSize: '50px', fontWeight: '700', textAlign: 'left', lineHeight: '1.2' }}>
                  {item.carousel_des_1}
                </p>
                <div className="mt-2 d-flex justify-content-center">
                  <a className="btn btn-green rounded-pill py-3 px-4 mb-0 me-3 fadeInUp animate__animated"
                    style={{ animationDelay: '1.7s' }} href="/aboutus">Read More<i className="fas fa-arrow-right ms-2"></i></a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
      {/* Header Carousel End */}

      {/* Aboutus  Start */}
      {data && (
        <div className="container-fluid about bg-light py-5">
          <div className="container py-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
                <div className="about-img">
                  <img src={"https://pineappleai-ai.com/api/images/" + data.img1} className="img-fluid w-100 rounded-top bg-white" alt="Image" />
                  <img src={"https://pineappleai-ai.com/api/images/" + data.img2} className="img-fluid w-100 rounded-bottom" alt="Image" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
                <br></br>
                <h4 className="text-green">About Us</h4>
                <h1 className="text-darkgreen">{data.aboutus_head}</h1>
                <p className="text ps-4 mb-4">{data.aboutus_des}</p>
                <div className="row g-4 justify-content-between mb-5">
                  <div className="col-lg-6 col-xl-5">
                    <p className="text-dark"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox1}</p>
                    <p className="text-dark mb-0"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox2}</p>
                  </div>
                  <div className="col-lg-6 col-xl-7">
                    <p className="text-dark"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox3}</p>
                    <p className="text-dark mb-0"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox4}</p>
                  </div>
                </div>
                <div className="row g-4 text-center align-items-center justify-content-center">
                  <div className="col-sm-4">
                    <div className="bg-secondary rounded p-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="counter-value fs-1 fw-bold text-white" data-toggle="counter-up">{data.pro_com_num}</span>
                        <h4 className="text-white fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                      </div>
                      <div className="w-100 d-flex align-items-center justify-content-center">
                        <p className="text-white mb-0">{data.pro_com_txt}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="bg-success rounded p-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="counter-value fs-1 fw-bold text-white" data-toggle="counter-up">{data.yrs_of_exp_txt_num}</span>
                        <h4 className="text-white fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                      </div>
                      <div className="w-100 d-flex align-items-center justify-content-center">
                        <p className="text-white mb-0">{data.yrs_of_exp_txt}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="bg-secondary rounded p-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="counter-value fs-1 fw-bold text-white" data-toggle="counter-up">{data.team_mem_num}</span>
                        <h4 className="text-white fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                      </div>
                      <div className="w-100 d-flex align-items-center justify-content-center">
                        <p className="text-white mb-0">{data.team_mem_txt}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Aboutus End */}


      {/* Project Start */}
      <div className="container-fluid project">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '800px' }}>
            <h4 className="text-green">Upcoming Projects</h4>
            <h1 className="text-darkgreen">Explore Our Upcoming Projects</h1>
          </div>
          <OwlCarousel className="project-carousel owl-carousel wow fadeInUp" {...projectOptions}>
            {projects.map((project, index) => (
              <div className="project-item h-100" key={index}>
                <div className="project-img">
                  <img src={`https://pineappleai-ai.com/api/images/${project.project_img}`} className="img-fluid w-100 rounded fixed-size-img" alt="Project" />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className={`fas ${project.project_icon} fa-4x text-green`}></i>
                    </div>
                    <p className="text-darkgreen fs-5 mb-3">{project.project_topic}</p>
                    <a href="#" className="h4 text-darkgreen"></a>
                    <div className="pt-4">
                      <a className="btn btn-primary rounded-pill py-3 px-5" href="#">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
      {/* Project End */}


      {/* Team Start */}
      {data_teams_member && (<div className="container-fluid team pb-5">
        <div className="container pb-5">
          <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '800px' }}>
            <h4 className="text-green">Our Team</h4>
            <h1 className="text-darkgreen">Our PineappleAI Company Dedicated Team Members</h1>
          </div>
          <OwlCarousel className="team-carousel owl-carousel" {...teamOptions}>
            {data_teams_member.map(member => (

              <div className="team-item rounded">
                <div className="team-img">
                  <img src={"https://pineappleai-ai.com/api/images/" + member.imageUrl} className="img-fluid w-100 rounded-top fixed-size-image" alt="Image" />
                  <div className="team-icon">
                    <a className="btn btn-primary btn-sm-square text-white rounded-circle mb-3" target="_blank" href={member.socialMedia.sociallist1}><i className="fab fa-linkedin-in"></i></a>

                  </div>
                </div>
                <div className="team-content bg-light text-center rounded-bottom p-4">
                  <div className="team-content-inner rounded-bottom">
                    <h4 className="text-black">{member.name}</h4>
                    <p className="text-muted mb-0">{member.position}</p>
                  </div>
                </div>
              </div>))}
          </OwlCarousel>
        </div>
      </div>)}
      {/* Team End */}
      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
      {/* Copyright Start */}
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <span className="text-body"><a href className="border-bottom text-white"><i className="fas fa-copyright text-light me-2" />PINEAPPLEAI.CLOUD </a>, All right reserved.</span>
            </div>
            <div className="col-md-6 text-center text-md-end text-body">
              Designed By <a className="border-bottom text-white" href>PineappleAI</a>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up" /></a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>

  );
}

export default Home;
