import React from 'react';
import { Link } from 'react-router-dom';
import './NotAvailablePage.css'; // Import your CSS file

function NotAvailablePage() {
  return (
    <div className="not-available-container">
      <div className="message-box">
        <h1 className="title">Currently, No Jobs Available</h1>
        <p className="message">We're sorry, but there are no job openings at the moment. Please check back later for available opportunities.</p>
        <Link to="/career" className="back-button">
          Back
        </Link>
      </div>
    </div>
  );
}

export default NotAvailablePage;
