import axios from 'axios';
import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'animate.css';
import Header from '../components/Header';
import Footer from './Footer';
// import OwlCarousel from 'react-owl-carousel';


function TeamSection() {
  const [loading, setLoading] = useState(true);
  const [data_teams_header, setteams_header] = useState(null);
  const [data_teams_member, setteams_member] = useState(null);

  const teamOptions = {
    items: 4,
    loop: true,
    autoplay: true,
    margin: 30,
    dots: false,
    nav: true,
    navText: ["<i class='bi bi-chevron-left'></i>", "<i class='bi bi-chevron-right'></i>"],
    responsive: {
      0: { items: 1 },
      576: { items: 2 },
      768: { items: 3 },
      992: { items: 4 }
    }
  };


  useEffect(() => {
    axios.get('https://pineappleai-ai.com/api/aboutus_teammember/view')
      .then(result => {
        console.log(result.data);
        setteams_member(result.data)

      })
      .catch(err => console.log(err))

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // simulate loading delay
    return () => clearTimeout(timer);

  }, []);


  if (loading) {
    return (
      <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  const teamItems = Math.min(data_teams_member.length, 4); // Limit to a maximum of 4 for large screens



  return (

    <div>
      {/* Header Start */}
      <Header />

      {/* Header end */}


      <br></br>
      <br></br>
      <br></br>


     {/* Team Start */}
     {data_teams_member && (<div className="container-fluid team pb-5">
        <div className="container pb-5">
          <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '800px' }}>
            <h4 className="text-green">Our Team</h4>
            <h1 className="text-darkgreen">Our PineappleAI Company Dedicated Team Members</h1>
          </div>
          <OwlCarousel className="team-carousel owl-carousel" {...teamOptions}>
            {data_teams_member.map(member => (

              <div className="team-item rounded">
                <div className="team-img">
                  <img src={"https://pineappleai-ai.com/api/images/" + member.imageUrl} className="img-fluid w-100 rounded-top fixed-size-image" alt="Image" />
                  <div className="team-icon">
                    <a className="btn btn-primary btn-sm-square text-white rounded-circle mb-3" target="_blank" href={member.socialMedia.sociallist1}><i className="fab fa-linkedin-in"></i></a>
                 
                  </div>
                </div>
                <div className="team-content bg-light text-center rounded-bottom p-4">
                  <div className="team-content-inner rounded-bottom">
                    <h4 className="text-black">{member.name}</h4>
                    <p className="text-muted mb-0">{member.position}</p>
                  </div>
                </div>
              </div>))}
          </OwlCarousel>
        </div>
      </div>)}
      {/* Team End */}

      <br></br>
      <br></br>
      <br></br>




      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
      {/* Copyright Start */}
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <span className="text-body"><a href className="border-bottom text-white"><i className="fas fa-copyright text-light me-2" />PINEAPPLEAI.CLOUD </a>, All right reserved.</span>
            </div>
            <div className="col-md-6 text-center text-md-end text-body">
              Designed By <a className="border-bottom text-white" href>PineappleAI</a>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up" /></a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>

  )
}

export default TeamSection;