import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CareerPage.css';
import Header from '../components/Header';
import Footer from './Footer';

const CareerPage = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/notavailable');
      };

      

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500); // simulate loading delay
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
    return (
        <div>
            <Header />

            <br></br>
            <br></br>


            <div className="career-page">
                <div className="content">
                    <h2>Careers Path</h2>
                    <h1>Work with us!</h1>
                    <p>
                        Welcome to our Career Page! Discover exciting opportunities and join a dynamic team
                        where your skills and ambitions can thrive. Let’s build a brighter future together.
                    </p>
                    
                    <br></br>
                    <button onClick={handleClick} className="view-careers-btn">VIEW CAREERS</button>
                    
                </div>
                <div className="image-container">
                    <img src="img/best-career-tests.webp" alt="Person working" />
                </div>
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <Footer />
        </div>
    );
};

export default CareerPage;
