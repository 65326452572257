import axios from 'axios';
import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'animate.css';
import Header from '../components/Header';
import Footer from './Footer';
// import OwlCarousel from 'react-owl-carousel';


function ProjectSection() {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  const projectOptions = {
    items: 3,
    loop: true,
    autoplay: true,
    margin: 30,
    dots: true,
    nav: true,
    navText: ["<i class='bi bi-chevron-left'></i>", "<i class='bi bi-chevron-right'></i>"],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      992: { items: 3 }
    }
  };


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://pineappleai-ai.com/api/project/view');
        setProjects(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchProjects();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // simulate loading delay
    return () => clearTimeout(timer);

  }, []);

  if (loading) {
    return (
      <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }




  return (

    <div>
      {/* Header Start */}
      <Header />
      {/* Header end */}

      <br></br>
      <br></br>
      <br></br>

      {/* Project Start */}
      <div className="container-fluid project">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '800px' }}>
            <h4 className="text-green">Upcoming Projects</h4>
            <h1 className="text-darkgreen">Explore Our Upcoming Projects</h1>
          </div>
          <OwlCarousel className="project-carousel owl-carousel wow fadeInUp" {...projectOptions}>
            {projects.map((project, index) => (
              <div className="project-item h-100" key={index}>
                <div className="project-img">
                  <img src={`https://pineappleai-ai.com/api/images/${project.project_img}`} className="img-fluid w-100 rounded fixed-size-img" alt="Project" />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className={`fas ${project.project_icon} fa-4x text-green`}></i>
                    </div>
                    <p className="text-darkgreen fs-5 mb-3">{project.project_topic}</p>
                    <a href="#" className="h4 text-darkgreen"></a>
                    <div className="pt-4">
                      <a className="btn btn-primary rounded-pill py-3 px-5" href="#">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
      {/* Project End */}

      <br></br>
      <br></br>
      <br></br>




      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
      {/* Copyright Start */}
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <span className="text-body"><a href className="border-bottom text-white"><i className="fas fa-copyright text-light me-2" />PINEAPPLEAI.CLOUD </a>, All right reserved.</span>
            </div>
            <div className="col-md-6 text-center text-md-end text-body">
              Designed By <a className="border-bottom text-white" href>PineappleAI</a>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up" /></a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>

  )
}

export default ProjectSection;