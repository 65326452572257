import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Home from './pages/Home';
import Login from "./pages/Login";
import Carousel from "./admin/Carousel";
import About from "./pages/About";
import Aboutus_edit from "./admin/Aboutus";
import Project from "./admin/Project";
import ContactUs from "./pages/Contact";
import AboutUsTeamMember from "./admin/AboutUsTeamMember";
import ProtectedRoute from './components/ProtectedRoute';

import { AuthProvider } from './context/AuthContext';
import AdminPanel from "./admin/adminpanel";
import ProjectSection from "./pages/Project";
import TeamSection from "./pages/teamsection";
import Header from "./components/Header";
import Footer from "./pages/Footer";
import AdminFooter from "./admin/footer";
import CareerPage from "./pages/Career";
import NotAvailablePage from "./pages/NotAvailablePage";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://pineappleai-ai.com/api/check-auth', { withCredentials: true }) // Ensure cookies are sent
      .then(response => {
        setIsAuthenticated(response.data.authenticated);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/carousel" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Carousel />
            </ProtectedRoute>
          } />
          <Route path="/adminpanel" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AdminPanel setIsAuthenticated={setIsAuthenticated} />
            </ProtectedRoute>
          } />
          <Route path="/aboutus_edit" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Aboutus_edit />
            </ProtectedRoute>
          } />
          <Route path="/project" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Project />
            </ProtectedRoute>
          } />
          <Route path="/AboutUsTeamMember" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AboutUsTeamMember />
            </ProtectedRoute>
          } />
             <Route path="/adminfooter" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AdminFooter />
            </ProtectedRoute>
          } />
          <Route path="/aboutus" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/projects" element={<ProjectSection />} />
          <Route path="/team" element={<TeamSection />} />
          <Route path="/headersection" element={<Header />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/notavailable" element={<NotAvailablePage />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
