import axios from 'axios';
import React, { useState, useEffect } from "react";
import AdminPanel from './adminpanel';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';


function Footer() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [initialData, setInitialData] = useState(null);

  // Initialize state for each section of the footer
  const [newsletter, setNewsletter] = useState({
    description: ''
    //email_placeholder: '',
    //signup_button_text: ''
  });
  const [explore, setExplore] = useState([{ label: '', url: '' }]);
  const [contactInfo, setContactInfo] = useState({
    address: '',
    email: '',
    phone: '',
    social_media: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: ''
    }
  });
  const [popularPosts, setPopularPosts] = useState([{ category: '', title: ''}]);
  // const [popularPosts, setPopularPosts] = useState([{ category: '', title: '', url: '' }]);
  const [viewAllPostsUrl, setViewAllPostsUrl] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [noChangesModal, setNoChangesModal] = useState(false);
  const [unsuccessModal, setunsuccessModal] = useState(false);
 



  useEffect(() => {
    axios.get('https://pineappleai-ai.com/api/footer/view')
      .then(result => {
        const data = result.data;
        setData(data);
        setInitialData(_.cloneDeep(data));
        // Initialize form fields with data from the API
        setNewsletter(data.newsletter);
        setExplore(data.explore);
        setContactInfo(data.contact_info);
        setPopularPosts(data.popular_posts);
        setViewAllPostsUrl(data.view_all_posts_url);
      })
      .catch(err => console.log(err));

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // simulate loading delay

    return () => clearTimeout(timer);
  }, []);

  const isDatabaseEmpty = !data || data.length === 0;

  const handleNewsletterChange = (field, value) => {
    setNewsletter({ ...newsletter, [field]: value });
  };

  const handleExploreChange = (index, field, value) => {
    const newExplore = [...explore];
    newExplore[index][field] = value;
    setExplore(newExplore);
  };

  const addExploreLink = () => {
    setExplore([...explore, { label: '', url: '' }]);
  };

  const removeExploreLink = (index) => {
    const newExplore = explore.filter((_, i) => i !== index);
    setExplore(newExplore);
  };

  const handleContactInfoChange = (field, value) => {
    setContactInfo({ ...contactInfo, [field]: value });
  };

  const handleSocialMediaChange = (field, value) => {
    setContactInfo({ ...contactInfo, social_media: { ...contactInfo.social_media, [field]: value } });
  };

  const handlePopularPostChange = (index, field, value) => {
    const newPopularPosts = [...popularPosts];
    newPopularPosts[index][field] = value;
    setPopularPosts(newPopularPosts);
  };

  const addPopularPost = () => {
    setPopularPosts([...popularPosts, { category: '', title: ''}]);
    // setPopularPosts([...popularPosts, { category: '', title: '', url: '' }]);
  };

  const removePopularPost = (index) => {
    const newPopularPosts = popularPosts.filter((_, i) => i !== index);
    setPopularPosts(newPopularPosts);
  };

  const hasChanges = () => {
    return !(
      _.isEqual(newsletter, initialData.newsletter) &&
      _.isEqual(explore, initialData.explore) &&
      _.isEqual(contactInfo, initialData.contact_info) &&
      _.isEqual(popularPosts, initialData.popular_posts) &&
      _.isEqual(viewAllPostsUrl, initialData.view_all_posts_url)
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();


 
    
      if (!isDatabaseEmpty) {
        if (!hasChanges()) {
          setNoChangesModal(true);
          return;
        }
      }
    

    if (!newsletter || !explore || !contactInfo || !popularPosts || !viewAllPostsUrl) {
      setErrorModal(true);
      return;
    }

    if (data) {
      setShowUpdateModal(true);
    } else {
      setShowAddModal(true);
    }
  };

  

  const Submit = (event) => {
    event.preventDefault();

    const updatedData = {
      newsletter,
      explore,
      contact_info: contactInfo,
      popular_posts: popularPosts,
      view_all_posts_url: viewAllPostsUrl
    };

    if (data) {
      
        axios.put('https://pineappleai-ai.com/api/footer/update/' + data._id, updatedData)
          .then(response => {
            console.log('Data updated successfully:', response.data);
            setData(response.data);
            setShowUpdateModal(false)
            window.location.reload();
          })
          .catch(err => {console.log(err)
            setunsuccessModal(true)
            setShowUpdateModal(false)
          });
      
    } else {
      
        axios.post('https://pineappleai-ai.com/api/footer/create', updatedData)
          .then(response => {
            console.log('Data added successfully:', response.data);
            setShowAddModal(false)
            window.location.reload();
          })
          .catch(err => {console.log(err)
            setunsuccessModal(true)
            setShowAddModal(false)
          });
      
    }
  };

  const handleDelete = () => {
    if (window.confirm('Do you want to delete?')) {
      axios.delete('https://pineappleai-ai.com/api/footer/delete/' + data._id)
        .then(response => {
          console.log('Data deleted successfully:', response.data);
          setShowDeleteModal(false)
          window.location.reload();
        })
        .catch(err => {console.log(err)
          setunsuccessModal(true)
          setShowDeleteModal(false)
        });
    }
  };

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  // if (loading) {
  //   return (
  //     <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
  //       <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
  //         <span className="sr-only">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }



  return (
    <div>
      <AdminPanel>
        <div className="container py-5">
          <h2>Update Footer Information</h2>
          <form onSubmit={handleSubmit}>
            {/* Newsletter Fields */}
            <div className="mb-3">
              <label htmlFor="newsletterDescription" className="form-label">Newsletter Description</label>
              <textarea
                className="form-control"
                id="newsletterDescription"
                rows="3"
                value={newsletter.description}
                onChange={(e) => handleNewsletterChange('description', e.target.value)}
                disabled={!isEditable}
                required
              ></textarea>
            </div>
            {/* <div className="mb-3">
              <label htmlFor="emailPlaceholder" className="form-label">Email Placeholder</label>
              <input
                type="text"
                className="form-control"
                id="emailPlaceholder"
                value={newsletter.email_placeholder}
                onChange={(e) => handleNewsletterChange('email_placeholder', e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="signupButtonText" className="form-label">Signup Button Text</label>
              <input
                type="text"
                className="form-control"
                id="signupButtonText"
                value={newsletter.signup_button_text}
                onChange={(e) => handleNewsletterChange('signup_button_text', e.target.value)}
                disabled={!isEditable}
                required
              />
            </div> */}

            {/* Explore Links */}
            <div className="mb-3">
              <label className="form-label">Explore Links</label>
              {explore.map((link, index) => (
                <div key={index} className="d-flex mb-2">
                  <input
                    type="text"
                    className="form-control me-2"
                    placeholder="Label"
                    value={link.label}
                    onChange={(e) => handleExploreChange(index, 'label', e.target.value)}
                    disabled={!isEditable}
                    required
                  />
                  <input
                    type="text"
                    className="form-control me-2"
                    placeholder="URL"
                    value={link.url}
                    onChange={(e) => handleExploreChange(index, 'url', e.target.value)}
                    disabled={!isEditable}
                    required
                  />
                  {isEditable && (
                    <button type="button" className="btn btn-danger" onClick={() => removeExploreLink(index)}>Delete</button>
                  )}
                </div>
              ))}
              {isEditable && (
                <button type="button" className="btn btn-secondary" onClick={addExploreLink}>Add Link</button>
              )}
            </div>

            {/* Contact Info Fields */}
            <div className="mb-3">
              <label htmlFor="contactAddress" className="form-label">Contact Address</label>
              <input
                type="text"
                className="form-control"
                id="contactAddress"
                value={contactInfo.address}
                onChange={(e) => handleContactInfoChange('address', e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactEmail" className="form-label">Contact Email</label>
              <input
                type="email"
                className="form-control"
                id="contactEmail"
                value={contactInfo.email}
                onChange={(e) => handleContactInfoChange('email', e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactPhone" className="form-label">Contact Phone</label>
              <input
                type="text"
                className="form-control"
                id="contactPhone"
                value={contactInfo.phone}
                onChange={(e) => handleContactInfoChange('phone', e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>

            {/* Social Media Links */}
            <div className="mb-3">
              <label className="form-label">Social Media Links</label>
              {Object.keys(contactInfo.social_media).map((platform) => (
                <div key={platform} className="mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={platform.charAt(0).toUpperCase() + platform.slice(1)}
                    value={contactInfo.social_media[platform]}
                    onChange={(e) => handleSocialMediaChange(platform, e.target.value)}
                    disabled={!isEditable}
                  />
                </div>
              ))}
            </div>

            {/* Popular Posts */}
            <div className="mb-3">
              <label className="form-label">Popular Posts</label>
              {popularPosts.map((post, index) => (
                <div key={index} className="d-flex mb-2">
                  <input
                    type="text"
                    className="form-control me-2"
                    placeholder="Category"
                    value={post.category}
                    onChange={(e) => handlePopularPostChange(index, 'category', e.target.value)}
                    disabled={!isEditable}
                    required
                  />
                  <input
                    type="text"
                    className="form-control me-2"
                    placeholder="Title"
                    value={post.title}
                    onChange={(e) => handlePopularPostChange(index, 'title', e.target.value)}
                    disabled={!isEditable}
                    required
                  />
                  {/* <input
                    type="text"
                    className="form-control me-2"
                    placeholder="URL"
                    value={post.url}
                    onChange={(e) => handlePopularPostChange(index, 'url', e.target.value)}
                    disabled={!isEditable}
                    required
                  /> */}
                  {isEditable && (
                    <button type="button" className="btn btn-danger" onClick={() => removePopularPost(index)}>Delete</button>
                  )}
                </div>
              ))}
              {isEditable && (
                <button type="button" className="btn btn-secondary" onClick={addPopularPost}>Add Post</button>
              )}
            </div>

            {/* View All Posts URL */}
            <div className="mb-3">
              <label htmlFor="viewAllPostsUrl" className="form-label">View All Posts URL</label>
              <input
                type="text"
                className="form-control"
                id="viewAllPostsUrl"
                value={viewAllPostsUrl}
                onChange={(e) => setViewAllPostsUrl(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary">
              {isDatabaseEmpty ? 'Add' : 'Update'}
            </button>
            <button type='button' className="btn btn-secondary" onClick={toggleEdit}>
              {isEditable ? 'Disable Editing' : 'Enable Editing'}
            </button>
            <button type='button' className="btn btn-danger" onClick={() => setShowDeleteModal(true)} disabled={isDatabaseEmpty}>Delete</button>
          </form>
        </div>


        {/* No Changes Modal */}
        <Modal show={noChangesModal} onHide={() => setNoChangesModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>No Changes Detected</Modal.Title>
          </Modal.Header>
          <Modal.Body>No changes were made. Please update the form before saving.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setNoChangesModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* unsuccess Modal */}
        <Modal show={unsuccessModal} onHide={() => setunsuccessModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please try again later.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setunsuccessModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Modal */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Add</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to add this information?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
              No
            </Button>
            <Button variant="primary" onClick={Submit}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Update Modal */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to update this information?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
              No
            </Button>
            <Button variant="primary" onClick={Submit}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this information?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              No
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={errorModal} onHide={() => setErrorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please fill in all required fields.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setErrorModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </AdminPanel>
    </div>
  );
}

export default Footer;