// src/admin/AdminPanel.js
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AdminPanel.css';

function AdminPanel({ children, setIsAuthenticated }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    axios.post('https://pineappleai-ai.com/api/logout', {}, { withCredentials: true })
      .then(() => {
        setIsAuthenticated(false); // Update authentication state
        navigate('/login'); // Redirect to login
      })
      .catch(err => {
        console.log(err);
        alert('Logout failed'); // Replace with toast for consistency
      });
  };

  return (
    <div className={`admin-panel ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <nav className="sidebar">
        <div className="sidebar-header">
          <img src="img/logo.jpeg" alt="Profile" className="profile-pic" />
          <div className="profile-info">
            <span>PINEAPPLEAI</span>
          </div>
        </div>
        <ul className="sidebar-menu">
          <li>
            <Link to="/" className={`menu-item ${location.pathname === '/' ? 'active' : ''}`}>Dashboard</Link>
          </li>
          <li className="menu-item dropdown">
            <span onClick={toggleDropdown} className="dropdown-toggle">Home</span>
            {dropdownOpen && (
              <div className="dropdown">
                <Link to="/carousel" className={`menu-item ${location.pathname === '/carousel' ? 'active' : ''}`}>Carousel</Link>
                <Link to="/aboutus_edit" className={`menu-item ${location.pathname === '/aboutus_edit' ? 'active' : ''}`}>About</Link>
                <Link to="/project" className={`menu-item ${location.pathname === '/project' ? 'active' : ''}`}>Project</Link>
                <Link to="/AboutUsTeamMember" className={`menu-item ${location.pathname === '/AboutUsTeamMember' ? 'active' : ''}`}>AboutUs Team Member</Link>
                <Link to="/adminfooter" className={`menu-item ${location.pathname === '/adminfooter' ? 'active' : ''}`}>Footer</Link>
              </div>
            )}
          </li>
        </ul>
      </nav>
      <div className="main-content">
        <header className="navbar">
          <button onClick={toggleSidebar} className="toggle-sidebar-btn">
            ☰
          </button>
          <div className="search-bar">
            <input type="text" placeholder="Search here" />
          </div>
          <div className="navbar-right">
            <div className="messages">
              <span>✉️</span>
            </div>
            
            <div className="notifications">
              <span>🔔</span>
            </div>
            
            <div className="profile">
               <li>
            <button onClick={handleLogout} className="menu-item logout-btn">Logout</button>
          </li>
            </div>
            
          </div>
        </header>
        <div className="dashboard-content">
          {children} {/* Render children components here */}
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
