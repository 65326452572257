import axios from 'axios';
import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';


import OwlCarousel from 'react-owl-carousel';
import AdminPanel from './adminpanel';
import 'animate.css';

function About() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  // Define individual states for each form field
  const [initialData, setInitialData] = useState(null);
  const [aboutusHead, setAboutusHead] = useState('');
  const [aboutusDes, setAboutusDes] = useState('');
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [file1, setFile1] = useState(null);
  const [imagePreviewUrl1, setImagePreviewUrl1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [imagePreviewUrl2, setImagePreviewUrl2] = useState(null);
  const [checkbox1, setCheckbox1] = useState('');
  const [checkbox2, setCheckbox2] = useState('');
  const [checkbox3, setCheckbox3] = useState('');
  const [checkbox4, setCheckbox4] = useState('');
  const [proComTxt, setProComTxt] = useState('');
  const [proComNum, setProComNum] = useState('');
  const [teamMemTxt, setTeamMemTxt] = useState('');
  const [teamMemNum, setTeamMemNum] = useState('');
  const [yrsOfExpTxt, setYrsOfExpTxt] = useState('');
  const [yrsOfExpNum, setYrsOfExpNum] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [noChangesModal, setNoChangesModal] = useState(false);
  const [unsuccessModal, setunsuccessModal] = useState(false);

  useEffect(() => {
    axios.get('https://pineappleai-ai.com/api/aboutus/view1')
      .then(result => {
        const data = result.data;
        setData(data);
        setInitialData(data);

        // Initialize form fields with data from the API
        setAboutusHead(data.aboutus_head);
        setAboutusDes(data.aboutus_des);
        setImg1(data.img1);
        setImg2(data.img2);
        setImagePreviewUrl1(`https://pineappleai-ai.com/api/images/${data.img1}`);
        setImagePreviewUrl2(`https://pineappleai-ai.com/api/images/${data.img2}`);
        setCheckbox1(data.checkbox1);
        setCheckbox2(data.checkbox2);
        setCheckbox3(data.checkbox3);
        setCheckbox4(data.checkbox4);
        setProComTxt(data.pro_com_txt);
        setProComNum(data.pro_com_num);
        setTeamMemTxt(data.team_mem_txt);
        setTeamMemNum(data.team_mem_num);
        setYrsOfExpTxt(data.yrs_of_exp_txt);
        setYrsOfExpNum(data.yrs_of_exp_txt_num);
      })
      .catch(err => console.log(err))

    // const timer = setTimeout(() => {
    //   setLoading(false);
    // }, 2000); // simulate loading delay

    // return () => clearTimeout(timer);
  }, []);

  const isDatabaseEmpty = !data || data.length === 0;

  // const handleTextInputChange = (setter) => (e) => {
  //   const value = e.target.value;
  //   if (/^[a-zA-Z\s]*$/.test(value)) {
  //     setter(value);
  //   } else {
  //     alert('Please enter only letters.');
  //   }
  // };
  const handleImageChange1 = (e) => {
    const selectedFile1 = e.target.files[0];
    setFile1(selectedFile1);
    setImg1(selectedFile1);
    setImagePreviewUrl1(URL.createObjectURL(selectedFile1));
  };

  const handleDrop1 = (e) => {
    e.preventDefault();
    const droppedFile1 = e.dataTransfer.files[0];
    setFile1(droppedFile1);
    setImg1(droppedFile1);
    setImagePreviewUrl1(URL.createObjectURL(droppedFile1));
  };

  const handleImageChange2 = (e) => {
    const selectedFile2 = e.target.files[0];
    setFile2(selectedFile2);
    setImg2(selectedFile2);
    setImagePreviewUrl2(URL.createObjectURL(selectedFile2));
  };

  const handleDrop2 = (e) => {
    e.preventDefault();
    const droppedFile2 = e.dataTransfer.files[0];
    setFile2(droppedFile2);
    setImg2(droppedFile2);
    setImagePreviewUrl2(URL.createObjectURL(droppedFile2));
  };



  const hasChanges = () => {
    return (
      aboutusHead !== (initialData.aboutus_head || '') ||
      aboutusDes !== initialData.aboutus_des ||
      img1 !== initialData.img1 ||
      img2 !== initialData.img2 ||
      checkbox1 !== initialData.checkbox1 ||
      checkbox2 !== initialData.checkbox2 ||
      checkbox3 !== initialData.checkbox3 ||
      checkbox4 !== initialData.checkbox4 ||
      proComTxt !== initialData.pro_com_txt ||
      proComNum !== initialData.pro_com_num ||
      teamMemTxt !== initialData.team_mem_txt ||
      teamMemNum !== initialData.team_mem_num ||
      yrsOfExpTxt !== initialData.yrs_of_exp_txt ||
      yrsOfExpNum !== initialData.yrs_of_exp_txt_num
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();



    if (!isDatabaseEmpty) {
      if (!hasChanges()) {
        setNoChangesModal(true);
        return;
      }
    }

    if (!aboutusHead || !aboutusDes || !proComTxt || !img1 || !img2 || !proComNum || !teamMemTxt || !teamMemNum || !yrsOfExpTxt || !yrsOfExpNum) {
      setErrorModal(true);
      return;
    }

    if (data) {
      setShowUpdateModal(true);
    } else {
      setShowAddModal(true);
    }
  };

  const confirmAdd = () => {
    const updatedData = {
      aboutus_head: aboutusHead,
      aboutus_des: aboutusDes,
      img1: img1,
      img2: img2,
      checkbox1: checkbox1,
      checkbox2: checkbox2,
      checkbox3: checkbox3,
      checkbox4: checkbox4,
      pro_com_txt: proComTxt,
      pro_com_num: proComNum,
      team_mem_txt: teamMemTxt,
      team_mem_num: teamMemNum,
      yrs_of_exp_txt: yrsOfExpTxt,
      yrs_of_exp_txt_num: yrsOfExpNum,
    };

    console.log(updatedData)

    axios.post('https://pineappleai-ai.com/api/aboutus/create', updatedData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(response => {
        console.log('Data added successfully:', response.data);
        setShowAddModal(false);
        window.location.reload();
      })
      .catch(err => {(console.log(err))
        setunsuccessModal(true)
      });
  };

  const confirmUpdate = () => {
    const updatedData = {
      aboutus_head: aboutusHead,
      aboutus_des: aboutusDes,
      img1: img1,
      img2: img2,
      checkbox1: checkbox1,
      checkbox2: checkbox2,
      checkbox3: checkbox3,
      checkbox4: checkbox4,
      pro_com_txt: proComTxt,
      pro_com_num: proComNum,
      team_mem_txt: teamMemTxt,
      team_mem_num: teamMemNum,
      yrs_of_exp_txt: yrsOfExpTxt,
      yrs_of_exp_txt_num: yrsOfExpNum,
    };

    axios.put('https://pineappleai-ai.com/api/aboutus/update/' + data._id, updatedData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(response => {
        console.log('Data updated successfully:', response.data);
        setShowUpdateModal(false);
        window.location.reload();
      })
      .catch(err => {console.log(err)
        setunsuccessModal(true)
      }
    );
  };

  const confirmDelete = () => {
    axios.delete('https://pineappleai-ai.com/api/aboutus/delete/' + data._id)
      .then(response => {
        console.log('Data deleted successfully:', response.data);
        setShowDeleteModal(false);
        window.location.reload();
      })
      .catch(err => console.log(err));
  };





  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  // if (loading) {
  //   return (
  //     <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
  //       <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
  //         <span className="sr-only">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (


    <div>

      {/* Form Section */}
      <AdminPanel>
        <div className="container py-5">
          <h2>Update About Us Information</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="aboutus_head" className="form-label w-50">About Us Headline</label>
              <input
                type="text"
                className="form-control"
                id="aboutus_head"
                value={aboutusHead}
                //onChange={handleTextInputChange(setAboutusHead)}
                onChange={(e) => setAboutusHead(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="aboutus_des" className="form-label">About Us Description</label>
              <textarea
                className="form-control"
                id="aboutus_des"
                rows="3"
                value={aboutusDes}
                onChange={(e) => setAboutusDes(e.target.value)}
                disabled={!isEditable}
                required
              ></textarea>
            </div>
            <div className="mb-3">
              <label className="form-label">Image 1</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleImageChange1}
                disabled={!isEditable}
              />
              {imagePreviewUrl1 && <img src={imagePreviewUrl1} alt="Image Preview" width="200" />}
            </div>
            <div
              className="mb-3"
              onDrop={handleDrop1}
              onDragOver={(e) => e.preventDefault()}
              style={{ border: "2px dashed #ccc", padding: "10px", borderRadius: "10px" }}
            >
              <p>Drag and drop Image 1 here</p>
              
            </div>
            <div className="mb-3">
              <label className="form-label">Image 2</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleImageChange2}
                disabled={!isEditable}
              />
              {imagePreviewUrl2 && <img src={imagePreviewUrl2} alt="Image Preview" width="200" />}
            </div>
            <div
              className="mb-3"
              onDrop={handleDrop2}
              onDragOver={(e) => e.preventDefault()}
              style={{ border: "2px dashed #ccc", padding: "10px", borderRadius: "10px" }}
            >
              <p>Drag and drop Image 2 here</p>
              
            </div>
            <div className="mb-3">
              <label htmlFor="checkbox1" className="form-label">Checkbox 1</label>
              <input
                type="text"
                className="form-control"
                id="checkbox1"
                value={checkbox1}
                onChange={(e) => setCheckbox1(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="checkbox2" className="form-label">Checkbox 2</label>
              <input
                type="text"
                className="form-control"
                id="checkbox2"
                value={checkbox2}
                onChange={(e) => setCheckbox2(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="checkbox3" className="form-label">Checkbox 3</label>
              <input
                type="text"
                className="form-control"
                id="checkbox3"
                value={checkbox3}
                onChange={(e) => setCheckbox3(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="checkbox4" className="form-label">Checkbox 4</label>
              <input
                type="text"
                className="form-control"
                id="checkbox4"
                value={checkbox4}
                onChange={(e) => setCheckbox4(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="proComTxt" className="form-label">Project complete text</label>
              <input
                type="text"
                className="form-control"
                id="proComTxt"
                value={proComTxt}
                onChange={(e) => setProComTxt(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="proComNum" className="form-label">Project complete number</label>
              <input
                type="number"
                className="form-control"
                id="proComNum"
                value={proComNum}
                onChange={(e) => setProComNum(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="teamMemTxt" className="form-label">Team Members Text</label>
              <input
                type="text"
                className="form-control"
                id="teamMemTxt"
                value={teamMemTxt}
                onChange={(e) => setTeamMemTxt(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="teamMemNum" className="form-label">Team Members Number</label>
              <input
                type="number"
                className="form-control"
                id="teamMemNum"
                value={teamMemNum}
                onChange={(e) => setTeamMemNum(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="yrsOfExpTxt" className="form-label">Years of Experience Text</label>
              <input
                type="text"
                className="form-control"
                id="yrsOfExpTxt"
                value={yrsOfExpTxt}
                onChange={(e) => setYrsOfExpTxt(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="yrsOfExpNum" className="form-label">Years of Experience Number</label>
              <input
                type="number"
                className="form-control"
                id="yrsOfExpNum"
                value={yrsOfExpNum}
                onChange={(e) => setYrsOfExpNum(e.target.value)}
                disabled={!isEditable}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary m-1">
              {isDatabaseEmpty ? 'Add' : 'Save'}
            </button>
            <button type='button' className="btn btn-secondary m-1" onClick={toggleEdit}>
              {isEditable ? 'Disable Editing' : 'Enable Editing'}
            </button>
            <button type='button' className="btn btn-danger m-1" onClick={() => setShowDeleteModal(true)} disabled={isDatabaseEmpty}>Delete</button>

          </form>



        </div>

        {/* No Changes Modal */}
        <Modal show={noChangesModal} onHide={() => setNoChangesModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>No Changes Detected</Modal.Title>
          </Modal.Header>
          <Modal.Body>No changes were made. Please update the form before saving.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setNoChangesModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* unsuccess Modal */}
        <Modal show={unsuccessModal} onHide={() => setunsuccessModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please try again later.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setunsuccessModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Modal */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Add</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to add this information?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
              No
            </Button>
            <Button variant="primary" onClick={confirmAdd}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Update Modal */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to update this information?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
              No
            </Button>
            <Button variant="primary" onClick={confirmUpdate}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this information?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              No
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={errorModal} onHide={() => setErrorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please fill in all required fields.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setErrorModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </AdminPanel>
    </div>

  );
}

export default About;
