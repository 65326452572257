// Footer.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Footer = () => {
    const [footerData, setFooterData] = useState(null);

    useEffect(() => {
        axios.get('https://pineappleai-ai.com/api/footer/view')
            .then(response => setFooterData(response.data))
            .catch(error => console.error('Error fetching footer data:', error));
    }, []);

    if (!footerData) return null;

    return (
        <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="text-white mb-4">Newsletter</h4>
                            <p className="text-white mb-3">{footerData.newsletter.description}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="text-white mb-4">Explore</h4>
                            {footerData.explore.map((item, index) => (
                                <a key={index} href={item.url}><i className="fas fa-angle-right me-2" /> {item.label}</a>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="text-white mb-4">Contact Info</h4>
                            <a href=""><i className="fa fa-map-marker-alt me-2" /> {footerData.contact_info.address}</a>
                            <a href=""><i className="fas fa-envelope me-2" /> {footerData.contact_info.email}</a>
                            <a href=""><i className="fas fa-phone me-2" /> {footerData.contact_info.phone}</a>
                            <div className="d-flex align-items-center">
                                <a className="btn btn-light btn-md-square me-2" target="_blank" href={footerData.contact_info.social_media.facebook}><i className="fab fa-facebook-f" /></a>
                                <a className="btn btn-light btn-md-square me-2" target="_blank" href={footerData.contact_info.social_media.twitter}><i className="fab fa-youtube" /></a>
                                <a className="btn btn-light btn-md-square me-2" target="_blank" href={footerData.contact_info.social_media.instagram}><i className="fab fa-instagram" /></a>
                                <a className="btn btn-light btn-md-square me-0" target="_blank" href={footerData.contact_info.social_media.linkedin}><i className="fab fa-linkedin-in" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item-post d-flex flex-column">
                            <h4 className="text-white mb-4">Popular Post</h4>
                            {footerData.popular_posts.map((post, index) => (
                                <div key={index} className="d-flex flex-column mb-3">
                                    <p className="text-uppercase text-white mb-2">{post.category}</p>
                                    <a className="text-white">{post.title}</a>
                                </div>
                            ))}
                            <div className="footer-btn text-start">
                                <a href={footerData.view_all_posts_url} className="btn btn-light rounded-pill px-4">View All Posts <i className="fa fa-arrow-right ms-1" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;