// import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { Modal, Button } from 'react-bootstrap';
// import './Carousel.css'; 
// import AdminPanel from './adminpanel';

// function Project() {
//   const [projectTopic, setProjectTopic] = useState('');
//   const [file, setFile] = useState(null);
//   const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
//   const [iconType, setIconType] = useState('');
//   const [projectData, setProjectData] = useState([]);
//   const [currentItemId, setCurrentItemId] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [deleteItemId, setDeleteItemId] = useState(null);
//   const navigate = useNavigate();

//   const handleImageChange = (e) => {
//     const selectedFile = e.target.files[0];
//     setFile(selectedFile);
//     setImagePreviewUrl(URL.createObjectURL(selectedFile));
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     const droppedFile = e.dataTransfer.files[0];
//     setFile(droppedFile);
//     setImagePreviewUrl(URL.createObjectURL(droppedFile));
//   };

//   useEffect(() => {
//     fetchProjectData();
//   }, []);

//   const fetchProjectData = () => {
//     axios.get('https://pineappleai-ai.com/api/project/view')
//         .then(result => setProjectData(result.data))
//         .catch(err => console.log(err));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('project_img', file);
//     formData.append('project_icon', iconType); 
//     formData.append('project_topic', projectTopic);

//     if (currentItemId) {
//       axios.put(`https://pineappleai-ai.com/api/project/update/${currentItemId}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       })
//       .then(result => {
//         console.log(result);
//         alert('Project updated successfully!');
//         setProjectTopic('');
//         setFile(null);
//         setImagePreviewUrl(null);
//         setIconType('');
//         setCurrentItemId(null);
//         fetchProjectData();
//         navigate('/project');
//       })
//       .catch(err => {
//         console.error('Error updating form:', err);
//         alert('There was an error updating the project!');
//       });
//     } else {
//       axios.post("https://pineappleai-ai.com/api/project/create", formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       })
//       .then(result => {
//         console.log(result);
//         alert('Project submitted successfully!');
//         setProjectTopic('');
//         setFile(null);
//         setImagePreviewUrl(null);
//         setIconType('');
//         fetchProjectData();
//         navigate('/project');
//       })
//       .catch(err => {
//         console.error('Error submitting form:', err);
//         alert('There was an error submitting the project!');
//       });
//     }
//   };

//   const handleDelete = (id) => {
//     setShowModal(true);
//     setDeleteItemId(id);
//   };

//   const confirmDelete = () => {
//     if (deleteItemId) {
//       axios.delete(`https://pineappleai-ai.com/api/project/delete/${deleteItemId}`)
//         .then(response => {
//           console.log(response.data);
//           setShowModal(false);
//           setDeleteItemId(null);
//           fetchProjectData();
//         })
//         .catch(error => {
//           console.error(error);
//         });
//     }
//   };

//   const handleUpdate = (item) => {
//     setProjectTopic(item.project_topic);
//     setFile(null);
//     setImagePreviewUrl(`https://pineappleai-ai.com/api/images/${item.project_img}`);
//     setIconType(item.project_icon); // Ensure this is the correct field name
//     setCurrentItemId(item._id);
//   };

//   return (
//     <AdminPanel>
//       <div className='container mt-5'>
//         <div className='d-flex justify-content-center align-items-center'>
//           <div className='p-4 border rounded' style={{ width: '100%', maxWidth: '600px' }}>
//             <h2 className='text-center mb-4'>Project Section</h2>
//             <form onSubmit={handleSubmit}>
//               <div className='mb-3'>
//                 <label className='form-label'>Project Image</label>
//                 <div 
//                   className='drag-drop-box'
//                   onDragOver={(e) => e.preventDefault()}
//                   onDrop={handleDrop}
//                 >
//                   <input
//                     type="file"
//                     onChange={handleImageChange}
//                     className='form-control'
//                     style={{ display: 'none' }}
//                     id="fileUpload"
//                   />
//                   <label htmlFor="fileUpload" className="d-block text-center">
//                     {file ? file.name : "Drag & drop an image here or click to select"}
//                   </label>
//                 </div>
//                 {imagePreviewUrl && (
//                   <div className="mt-2 text-center">
//                     <img src={imagePreviewUrl} alt="Preview" width="100" />
//                   </div>
//                 )}
//               </div>

//               <div className='mb-3'>
//                 <label className='form-label'>Project Icon Type</label>
//                 <input
//                   type="text"
//                   placeholder='Enter icon type code'
//                   className='form-control'
//                   value={iconType}
//                   onChange={(e) => setIconType(e.target.value)}
//                   required
//                 />
//               </div>

//               <div className='mb-3'>
//                 <label htmlFor="projectTopic" className='form-label'>Project Topic</label>
//                 <input
//                   type="text"
//                   id="projectTopic"
//                   placeholder='Enter project topic'
//                   className='form-control'
//                   value={projectTopic}
//                   onChange={(e) => setProjectTopic(e.target.value)}
//                   required
//                 />
//               </div>
//               <div className='d-flex justify-content-center'>
//                 <button type='submit' className='btn btn-primary'>{currentItemId ? 'Update' : 'Add'}</button>
//               </div>
//             </form>
//           </div>
//         </div>

//         <div className='mt-5 d-flex justify-content-center'>
//           <div className="table-responsive" style={{ maxWidth: '1000px' }}>
//             <h3 className='text-center'>Project Data</h3>
//             <table className='table table-striped'>
//               <thead>
//                 <tr>
//                   <th>Image</th>
//                   <th>Icon</th>
//                   <th>Topic</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {projectData.map((item, index) => (
//                   <tr key={index}>
//                     <td><img src={`https://pineappleai-ai.com/api/images/${item.project_img}`} alt='project' width='100' /></td>
//                     <td>{item.project_icon}</td> {/* Ensure consistency with backend */}
//                     <td>{item.project_topic}</td>
//                     <td>
//                       <button className='btn btn-primary me-2' onClick={() => handleUpdate(item)}>Update</button>
//                       <button className='btn btn-danger' onClick={() => handleDelete(item._id)}>Delete</button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Deletion</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Are you sure you want to delete this project?</Modal.Body>
//         <Modal.Footer>
//         <Button variant="danger" onClick={confirmDelete}>
//             Yes
//           </Button>
//         <Button variant="secondary" onClick={() => setShowModal(false)}>
//             No
//           </Button>
          
//         </Modal.Footer>
//       </Modal>
//     </AdminPanel>
//   );
// }

// export default Project;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import './Carousel.css'; 
import AdminPanel from './adminpanel';

function Project() {
  const [projectTopic, setProjectTopic] = useState('');
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [iconType, setIconType] = useState('');
  const [projectData, setProjectData] = useState([]);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [message, setMessage] = useState(''); // Message state
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setImagePreviewUrl(URL.createObjectURL(selectedFile));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setImagePreviewUrl(URL.createObjectURL(droppedFile));
  };

  useEffect(() => {
    fetchProjectData();
  }, []);

  const fetchProjectData = () => {
    axios.get('https://pineappleai-ai.com/api/project/view')
        .then(result => setProjectData(result.data))
        .catch(err => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation: only required when adding a new item
    if (!currentItemId && (!file || !projectTopic || !iconType)) {
      setMessage('All fields are required!');
      clearMessageAfterDelay();
      return;
    }

    const formData = new FormData();
    formData.append('project_img', file);
    formData.append('project_icon', iconType); 
    formData.append('project_topic', projectTopic);

    const request = currentItemId 
      ? axios.put(`https://pineappleai-ai.com/api/project/update/${currentItemId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      : axios.post("https://pineappleai-ai.com/api/project/create", formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

    request.then(result => {
      setMessage(currentItemId ? 'Project updated successfully!' : 'Project submitted successfully!');
      clearMessageAfterDelay();
      resetForm();
      fetchProjectData();
      navigate('/project');
    })
    .catch(err => {
      console.error('Error submitting form:', err);
      setMessage('There was an error submitting the project!');
      clearMessageAfterDelay();
    });
  };

  const clearMessageAfterDelay = () => {
    setTimeout(() => {
      setMessage('');
    }, 3000); 
  };

  const resetForm = () => {
    setProjectTopic('');
    setFile(null);
    setImagePreviewUrl(null);
    setIconType('');
    setCurrentItemId(null);
  };

  const handleDelete = (id) => {
    setShowModal(true);
    setDeleteItemId(id);
  };

  const confirmDelete = () => {
    if (deleteItemId) {
      axios.delete(`https://pineappleai-ai.com/api/project/delete/${deleteItemId}`)
        .then(response => {
          setShowModal(false);
          setDeleteItemId(null);
          setMessage('Project deleted successfully!');
          clearMessageAfterDelay();
          fetchProjectData();
        })
        .catch(error => {
          console.error(error);
          setMessage('There was an error deleting the project!');
          clearMessageAfterDelay();
        });
    }
  };

  const handleUpdate = (item) => {
    setProjectTopic(item.project_topic);
    setFile(null);
    setImagePreviewUrl(`https://pineappleai-ai.com/api/images/${item.project_img}`);
    setIconType(item.project_icon); 
    setCurrentItemId(item._id);
  };

  return (
    <AdminPanel>
      <div className='container mt-5'>
        {message && <div className="alert alert-info text-center">{message}</div>}
        <div className='d-flex justify-content-center align-items-center'>
          <div className='p-4 border rounded' style={{ width: '100%', maxWidth: '600px' }}>
            <h2 className='text-center mb-4'>Project Section</h2>
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label className='form-label'>Project Image</label>
                <div 
                  className='drag-drop-box'
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    onChange={handleImageChange}
                    className='form-control'
                    style={{ display: 'none' }}
                    id="fileUpload"
                  />
                  <label htmlFor="fileUpload" className="d-block text-center">
                    {file ? file.name : "Drag & drop an image here or click to select"}
                  </label>
                </div>
                {imagePreviewUrl && (
                  <div className="mt-2 text-center">
                    <img src={imagePreviewUrl} alt="Preview" width="100" />
                  </div>
                )}
              </div>

              <div className='mb-3'>
                <label className='form-label'>Project Icon Type</label>
                <input
                  type="text"
                  placeholder='Enter icon type code'
                  className='form-control'
                  value={iconType}
                  onChange={(e) => setIconType(e.target.value)}
                  required
                />
              </div>

              <div className='mb-3'>
  <label htmlFor="projectTopic" className='form-label'>Project Topic</label>
  <input
    type="text"
    id="projectTopic"
    placeholder='Enter project topic'
    className='form-control'
    value={projectTopic}
    onChange={(e) => setProjectTopic(e.target.value.replace(/[0-9]/g, ''))}
    required
  />
</div>
              <div className='d-flex justify-content-center'>
                <button type='submit' className='btn btn-primary'>{currentItemId ? 'Update' : 'Add'}</button>
              </div>
            </form>
          </div>
        </div>

        <div className='mt-5 d-flex justify-content-center'>
          <div className="table-responsive" style={{ maxWidth: '1000px' }}>
            <h3 className='text-center'>Project Data</h3>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Icon</th>
                  <th>Topic</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {projectData.map((item, index) => (
                  <tr key={index}>
                    <td><img src={`https://pineappleai-ai.com/api/images/${item.project_img}`} alt='project' width='100' /></td>
                    <td>{item.project_icon}</td>
                    <td>{item.project_topic}</td>
                    <td>
                      <button className='btn btn-primary me-2' onClick={() => handleUpdate(item)}>Edit</button>
                      <button className='btn btn-danger' onClick={() => handleDelete(item._id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this project?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={confirmDelete}>
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </AdminPanel>
  );
}

export default Project;
