import axios from 'axios';
import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'animate.css';
import Header from '../components/Header';
import Footer from './Footer';
// import OwlCarousel from 'react-owl-carousel';


function About() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);





  useEffect(() => {

    axios.get('https://pineappleai-ai.com/api/aboutus/view1')
      .then(result => setData(result.data))
      .catch(err => console.log(err))

      .catch(err => console.log(err))

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // simulate loading delay
    return () => clearTimeout(timer);

  }, []);

  if (loading) {
    return (
      <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }





  return (

    <div>
      {/* Header Start */}
      <Header />

      {/* Header end */}

      <br></br>
      <br></br>

        {/* Aboutus  Start */}
      {data && (
  <div className="container-fluid about bg-light py-5">
    <div className="container py-5">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6 col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="about-img">
            <img src={"https://pineappleai-ai.com/api/images/" + data.img1} className="img-fluid w-100 rounded-top bg-white" alt="Image" />
            <img src={"https://pineappleai-ai.com/api/images/" + data.img2} className="img-fluid w-100 rounded-bottom" alt="Image" />
          </div>
        </div>
        <div className="col-lg-6 col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <br></br>
          <h4 className="text-green">About Us</h4>
          <h1 className="text-darkgreen">{data.aboutus_head}</h1>
          <p className="text ps-4 mb-4">{data.aboutus_des}</p>
          <div className="row g-4 justify-content-between mb-5">
            <div className="col-lg-6 col-xl-5">
              <p className="text-dark"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox1}</p>
              <p className="text-dark mb-0"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox2}</p>
            </div>
            <div className="col-lg-6 col-xl-7">
              <p className="text-dark"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox3}</p>
              <p className="text-dark mb-0"><i className="fas fa-check-circle text-green me-1" /> {data.checkbox4}</p>
            </div>
          </div>
          <div className="row g-4 text-center align-items-center justify-content-center">
            <div className="col-sm-4">
              <div className="bg-secondary rounded p-4">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="counter-value fs-1 fw-bold text-white" data-toggle="counter-up">{data.pro_com_num}</span>
                  <h4 className="text-white fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <p className="text-white mb-0">{data.pro_com_txt}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bg-success rounded p-4">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="counter-value fs-1 fw-bold text-white" data-toggle="counter-up">{data.yrs_of_exp_txt_num}</span>
                  <h4 className="text-white fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <p className="text-white mb-0">{data.yrs_of_exp_txt}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bg-secondary rounded p-4">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="counter-value fs-1 fw-bold text-white" data-toggle="counter-up">{data.team_mem_num}</span>
                  <h4 className="text-white fs-1 mb-0" style={{ fontWeight: 600, fontSize: '25px' }}>+</h4>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <p className="text-white mb-0">{data.team_mem_txt}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}
      {/* Aboutus End */}
      <br></br>
      <br></br>
      <br></br>



      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
      {/* Copyright Start */}
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <span className="text-body"><a href className="border-bottom text-white"><i className="fas fa-copyright text-light me-2" />PINEAPPLEAI.CLOUD </a>, All right reserved.</span>
            </div>
            <div className="col-md-6 text-center text-md-end text-body">
              Designed By <a className="border-bottom text-white" href>PineappleAI</a>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up" /></a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>

  )
}

export default About;